import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { mapQuery } from '@/services/http/wap/query/mappers'

import { path } from '@/core/utils'
import { useApp } from '@/core/contexts/app'
import { useWidth } from '@/core/hooks/useWidthResize'
import { Column, Container, Row } from '@/components/base/gridview'

import { LOGOS_MAPPING } from './constant'

import query from '@/services/http/wap/query'
import Iframe from '@/components/base/iframe'
import Anchor from '@/components/base/anchor'
import pageService from '@/services/http/wap/page'
import Image from '@/components/base/image'
import Button from '@/components/base/button/Button'

import { LogoItem } from '../asg-info-bar/types'
import { AsgFooterProps } from './types'

const AsgFooter: React.FunctionComponent<AsgFooterProps> = (props) => {
  const app = useApp()
  const width = useWidth()
  const router = useRouter()
  const template = app.theme.title

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  return (
    <footer
      className={`${props.isHome ? '' : 'not-home'} asg-footer ${
        props.isReverse ? 'asg-footer-reverse' : ''
      }`}
    >
      <Container
        size={
          width < 768
            ? 'extended'
            : template === 'Ana sayfa teması'
            ? 'extended'
            : 'wide'
        }
      >
        {(props.isHome || props.isContact) && (
          <Row className="footer-top-wrapper">
            <Column xl={{ size: 3 }} sm={{ size: 12 }} className="address-bar">
              <span
                className="title"
                dangerouslySetInnerHTML={{
                  __html: app.settings.translations['footerTitle'],
                }}
              />
              {props?.addresses?.map((item, index) => (
                <span key={index} className="address">
                  <div dangerouslySetInnerHTML={{ __html: item.address }} />
                </span>
              ))}
              <span className="mail">{props.mail}</span>
            </Column>
            <Column
              xl={{ size: 9 }}
              sm={{ size: 12 }}
              className={'iframe-wrapper'}
            >
              <Iframe src={props.mapCode} />
            </Column>
          </Row>
        )}
        <Row
          className={`${
            props.isHome || props.isContact ? '' : 'not-home'
          } footer-middle-wrapper`}
        >
          <Column
            xl={{ size: 3 }}
            sm={{ size: 12 }}
            className="footer-logo-wrapper"
          >
            <Row style={{ gap: '40px' }}>
              <Anchor className="footer-logo" href={app.settings.homepage}>
                <Image
                  {...(props.logo?.src ? props.logo : app.theme.logo)}
                  alt={app.page.title}
                  height={50}
                />
              </Anchor>
              <Anchor className="footer-logo" href={'https://asggrup.com'}>
                <Image
                  src="https://minio.asggrup.com/storage/Root/asggrup/ASG-Grup-Logo.svg"
                  alt={'ASG'}
                  height={100}
                />
              </Anchor>
            </Row>
            <span className="info">
              <div
                dangerouslySetInnerHTML={{
                  __html: app.settings.translations['copyright'],
                }}
              />
            </span>
            <ul className="language-wrapper">
              {app.languages.map((language) => {
                return (
                  <li key={language.id}>
                    <Button
                      onClick={() => handleLanguageSelection(language.id)}
                    >
                      {language.name.substring(0, 2)}
                    </Button>
                  </li>
                )
              })}
            </ul>
          </Column>
          <Column xl={{ size: 9 }} className={'footer-menu-wrapper'}>
            {props.navigation?.map((item, index) => (
              <div key={index}>
                {item.href === '#' ? (
                  <>
                    <div className="title">{item.title}</div>
                    <ul>
                      {item.children?.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Anchor href={subItem.href}>{subItem.title}</Anchor>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <>
                    <Anchor href={item.href}>{item.title}</Anchor>
                    <ul>
                      {item.children?.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Anchor href={subItem.href}>{subItem.title}</Anchor>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            ))}
          </Column>
        </Row>
        <span
          style={{ margin: '15px 0', display: 'block' }}
          dangerouslySetInnerHTML={{
            __html: app.settings.translations['ASGIstirakler'],
          }}
        />
        <Row className="footer-bottom-wrapper">
          <Column
            xl={{ size: 12 }}
            lg={{ size: 12 }}
            className={'copy-wrapper'}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: app.settings.translations['galata-proje'],
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: app.settings.translations['DesignByBuKafa'],
              }}
            />
          </Column>
          <Column
            xl={{ size: 12 }}
            lg={{ size: 12 }}
            className={'policy-wrapper'}
          >
            <ul>
              {props?.policyNavigation?.map((menuItem, index) => (
                <li key={index} className={menuItem.className}>
                  {menuItem.href.indexOf('http') === 0 ? (
                    <a
                      href={menuItem.href}
                      target={menuItem.target}
                      dangerouslySetInnerHTML={{ __html: menuItem.title }}
                      rel="noreferrer"
                    />
                  ) : (
                    <Anchor
                      href={menuItem.href}
                      dangerouslySetInnerHTML={{ __html: menuItem.title }}
                    />
                  )}
                </li>
              ))}
            </ul>
          </Column>
        </Row>
      </Container>
    </footer>
  )
}

export default AsgFooter
